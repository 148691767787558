const en = {
  link: 'Link Wallet',
  participants: 'The current number of participants',
  lottery: 'Lottery',
  recomment: 'Please enter the recommender',
  total: 'Total USDT',
  contract: 'The current contract',
  gameAward: 'Available game rewards',
  recommentAward: 'Available recommend rewards',
  available: 'Available ',
  rewards: 'rewards',
  bind: 'Bind',
  introduction: 'Game Introduction',
  detail: 'this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail',
  list: 'Winners of the previous round',
  first: 'First Prize',
  second: 'Second Prize',
  third: 'Third Prize',
  balance: ' Balance',
  receive: 'receive',
  burnSum: 'Total network combustion',
  burnProportion: 'Proportion of personal combustion',
  burnValue: 'Individual destroyed quantity',
  burn: "burn",
  max: "MAX",
  refBurn:"Destruction ratio obtained through the recommender",
  NetworkWideInformation:"GameInfo",
  NetworkRefBurn:"DestructionTatioOfRecommenders",
  RefBurnProportion:"Reward for destruction of recommender",
  PlayerInformation:"Player Information",
  ReceiveAnAward:"Income information",
  _r:"The recommender who the next round to take effect destroys the reward",
  _r1:"Quantity of destruction the next round to take effect",
  _r2:"Number of ETNs available in the current round",
  _r3:"Get ETND",
  _r4:"Owner Distribute dividends"
}

export default en;