const zh = {
  link: '连接钱包',
  participants: '当前参与人数',
  lottery: '抽奖',
  recomment: '请输入推荐人',
  total: 'USDT总额',
  contract: '当前合约',
  gameAward: '当前可领取的游戏奖励',
  recommentAward: '当前可领取的推荐奖励',
  available: '当前可领取的 ',
  rewards: ' ',
  bind: '绑定',
  introduction: '游戏介绍',
  detail: '这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯',
  list: '上一轮获奖名单',
  first: '一等奖',
  second: '二等奖',
  third: '三等奖',
  balance: ' 余额',
  receive: '领取',
  burnSum: '全网销毁总量',
  burnProportion: '个人总销毁数占全网比例',
  burnValue: '个人已销毁数量',
  burn: "销毁",
  max: "最大",
  refBurn:"通过推荐人获得的销毁比例",
  NetworkWideInformation:"全网信息",
  NetworkRefBurn:"全网推荐人销毁占比",
  RefBurnProportion:"推荐人销毁奖励",
  PlayerInformation:"玩家信息",
  ReceiveAnAward:"收益信息",
  _r:"下一轮生效的推荐人销毁奖励",
  _r1:"下一轮生效的销毁数量",
  _r2:"当轮可领取的ETND数量",
  _r3:"领取ETND",
  _r4:"GO"




}

export default zh;